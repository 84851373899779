class LocalDateTime {
    static regExpIso = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(?:Z|([+-])([\d|:]*))?$/;
    static daysInMonthNormalYear = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    static daysInMonthLeapYear = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    static monthNames = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];
    static weekDayNames = ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"];
    static holidays = {}


    static now() {
        return LocalDateTime.fromDate(new Date());
    }

    static today() {
        let date = new Date();
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        return LocalDateTime.fromDate(date);
    }

    static fromDate(date) {
        return new LocalDateTime(date.getFullYear(), date.getMonth() + 1, date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
    }

    static parseGermanDateTimeWithoutSeconds(string) {
        let exp = /^\s*([0-9][0-9])[\s.:-]*([0-9][0-9])[\s.:-]*([0-9][0-9][0-9][0-9])[\s.:-]*([0-9][0-9])[\s.:-]*([0-9][0-9])\s*$/;
        let tokens = exp.exec(string);
        if (tokens) {
            let day = parseInt(tokens[1]);
            let month = parseInt(tokens[2]);
            let year = parseInt(tokens[3]);
            let hour = parseInt(tokens[4]);
            let minute = parseInt(tokens[5]);
            let result = new LocalDateTime(year, month, day, hour, minute);
            if (result.isValid())
                return result;
            else
                return null;
        } else
            return null;
    }

    static parseIso(date) {
        let year = parseInt(date.substring(0, 4));
        let month = parseInt(date.substring(5, 7));
        let day = parseInt(date.substring(8, 10));
        let hour = parseInt(date.substring(11, 13));
        let minute = parseInt(date.substring(14, 16));
        let second = parseInt(date.substring(17, 19));
        let millisecond = date.charAt(19) === '.' ? parseInt(date.substring(20, 23)) : 0;
        return new LocalDateTime(year, month, day, hour, minute, second, millisecond);
    }

    constructor(year, month = 1, day = 1, hour = 0, minute = 0, second = 0, millisecond = 0) {
        this.year = year;
        this.month = month;
        this.day = day;
        this.hour = hour;
        this.minute = minute;
        this.second = second;
        this.millisecond = millisecond;
        this.lazyValIsHoliday = undefined;
    }

    getYear() {
        return this.year;
    }

    getMonth() {
        return this.month;
    }

    getMonthName() {
        return LocalDateTime.monthNames[this.month - 1];
    }

    getShortMonthName() {
        return this.getMonthName().substring(0, 3);
    }

    getShortYear() {
        return this.getYear().toString().substring(2);
    }

    getDay() {
        return this.day;
    }

    isValid() {
        return this.month >= 1 && this.month <= 12 &&
            this.day >= 1 && this.day <= this.getNumberOfDaysInMonth() &&
            this.hour >= 0 && this.hour <= 23 &&
            this.minute >= 0 && this.minute <= 59 &&
            this.second >= 0 && this.second <= 59 &&
            this.millisecond >= 0 && this.millisecond <= 999;
    }

    getSpecialDayName() {
        let result = [];
        if (this.day === 1 && this.month === 1)
            result.push("Neujahr");
        if (this.day === 6 && this.month === 1)
            result.push("Heilige Drei Könige");
        if (this.day === 1 && this.month === 5)
            result.push("Tag der Arbeit");
        if (this.day === 15 && this.month === 8)
            result.push("Mariä Himmelfahrt");
        if (this.day === 3 && this.month === 10)
            result.push("Tag der Deutschen Einheit");
        if (this.day === 31 && this.month === 10)
            result.push("Reformationstag / Helloween");
        if (this.day === 1 && this.month === 11)
            result.push("Allerheiligen");
        if (this.day === 6 && this.month === 12)
            result.push("Nikolaustag");
        if (this.day === 24 && this.month === 12)
            result.push("Heiligabend");
        if (this.day === 25 && this.month === 12)
            result.push("1. Weihnachtsfeiertag");
        if (this.day === 26 && this.month === 12)
            result.push("2. Weihnachtsfeiertag");
        if (this.day === 31 && this.month === 12)
            result.push("Silvester");

        if (this.month === 3 && this.day >= 25 && this.getDayOfWeek() === 7)
            result.push("Beginn der Sommerzeit");
        if (this.month === 5 && this.day > 7 && this.day < 15 && this.getDayOfWeek() === 7)
            result.push("Muttertag");
        if (this.month === 10 && this.day >= 25 && this.getDayOfWeek() === 7)
            result.push("Ende der Sommerzeit");
        let christmas = new LocalDateTime(this.year, 12, 25);
        if (this.equalsDate(christmas.addDays(-21 - christmas.getDayOfWeek())))
            result.push("1. Advent");
        if (this.equalsDate(christmas.addDays(-14 - christmas.getDayOfWeek())))
            result.push("2. Advent");
        if (this.equalsDate(christmas.addDays(-7 - christmas.getDayOfWeek())))
            result.push("3. Advent");
        if (this.equalsDate(christmas.addDays(-christmas.getDayOfWeek())))
            result.push("4. Advent");

        let easterDate = this.getEasterDate();
        if (this.equalsDate(easterDate.addDays(-52)))
            result.push("Weiberfastnacht");
        if (this.equalsDate(easterDate.addDays(-48)))
            result.push("Rosenmontag");
        if (this.equalsDate(easterDate.addDays(-46)))
            result.push("Aschermittwoch");
        if (this.equalsDate(easterDate.addDays(-2)))
            result.push("Karfreitag");
        if (this.equalsDate(easterDate))
            result.push("Ostersonntag");
        if (this.equalsDate(easterDate.addDays(1)))
            result.push("Ostermontag");
        if (this.equalsDate(easterDate.addDays(39)))
            result.push("Christi Himmelfahrt");
        if (this.equalsDate(easterDate.addDays(49)))
            result.push("Pfingsten");
        if (this.equalsDate(easterDate.addDays(50)))
            result.push("Pfingstmontag");
        if (this.equalsDate(easterDate.addDays(60)))
            result.push("Fronleichnam");
        if (result.length === 0)
            return "";
        else {
            let returnString = result[0];
            for (let index = 1; index < result.length; ++index)
                returnString += " / " + result[index];
            return returnString;
        }
    }

    getHour() {
        return this.hour;
    }

    getMinute() {
        return this.minute;
    }

    getSecond() {
        return this.second;
    }

    getMillisecond() {
        return this.millisecond;
    }

    isLeapYear(year = this.year) {
        return (year % 4) === 0 && ((year % 100) !== 0 || (year % 400) === 0);
    }

    getNumberOfDaysInMonth(year = this.year, month = this.month) {
        if (this.isLeapYear(year))
            return LocalDateTime.daysInMonthLeapYear[month - 1];
        else
            return LocalDateTime.daysInMonthNormalYear[month - 1];
    }

    getDayOfYear(year = this.year, month = this.month, day = this.day) {
        let result = day;
        let daysInMonth = this.isLeapYear(year) ? LocalDateTime.daysInMonthLeapYear : LocalDateTime.daysInMonthNormalYear;
        for (let m = 1; m < month; ++m)
            result += daysInMonth[m - 1];
        return result;
    }

    getDayOfWeek(year = this.year, month = this.month, day = this.day) {
        let result = 5;
        let yearsSince2000 = year - 2000;
        result += yearsSince2000;
        result += Math.ceil(yearsSince2000 / 4);
        result -= Math.ceil(yearsSince2000 / 100)
        result += Math.ceil(yearsSince2000 / 400)
        result += this.getDayOfYear(year, month, day) - 1;
        result = (result % 7) + 1;
        if (result < 1)
            result += 7;
        return result;
    }

    getDayOfWeekName() {
        return LocalDateTime.weekDayNames[this.getDayOfWeek() - 1];
    }

    addDays(numberOfDays, year = this.year, month = this.month, day = this.day) {
        let resultDay = day + numberOfDays;
        if (resultDay < 1)
            if (month === 1)
                return this.addDays(resultDay, year - 1, 12, 31);
            else
                return this.addDays(resultDay, year, month - 1, this.getNumberOfDaysInMonth(year, month - 1));
        let numberOfDaysInMonth = this.getNumberOfDaysInMonth(year, month);
        if (resultDay > numberOfDaysInMonth)
            if (month === 12)
                return this.addDays(resultDay - numberOfDaysInMonth - 1, year + 1, 1, 1);
            else
                return this.addDays(resultDay - numberOfDaysInMonth - 1, year, month + 1, 1);
        return new LocalDateTime(year, month, resultDay, this.hour, this.minute, this.second, this.millisecond);
    }

    addMonths(numberOfMonths) {
        let month = ((this.month + numberOfMonths - 1) % 12) + 1;
        if (month < 1)
            month += 12;
        let year = this.year + Math.floor((this.month + numberOfMonths - 1) / 12);
        let numberOfDaysInMonth = this.getNumberOfDaysInMonth(year, month);
        let day = this.day > numberOfDaysInMonth ? numberOfDaysInMonth : this.day;
        return new LocalDateTime(year, month, day, this.hour, this.minute, this.second, this.millisecond);
    }

    isBefore(that) {
        return this.year < that.year ||
            (this.year === that.year && this.month < that.month) ||
            (this.year === that.year && this.month === that.month && this.day < that.day) ||
            (this.year === that.year && this.month === that.month && this.day === that.day && this.hour < that.hour) ||
            (this.year === that.year && this.month === that.month && this.day === that.day && this.hour === that.hour && this.minute < that.minute) ||
            (this.year === that.year && this.month === that.month && this.day === that.day && this.hour === that.hour && this.minute === that.minute && this.second < that.second) ||
            (this.year === that.year && this.month === that.month && this.day === that.day && this.hour === that.hour && this.minute === that.minute && this.second === that.second && this.millisecond < that.millisecond);
    }

    isAfter(that) {
        return this.year > that.year ||
            (this.year === that.year && this.month > that.month) ||
            (this.year === that.year && this.month === that.month && this.day > that.day) ||
            (this.year === that.year && this.month === that.month && this.day === that.day && this.hour > that.hour) ||
            (this.year === that.year && this.month === that.month && this.day === that.day && this.hour === that.hour && this.minute > that.minute) ||
            (this.year === that.year && this.month === that.month && this.day === that.day && this.hour === that.hour && this.minute === that.minute && this.second > that.second) ||
            (this.year === that.year && this.month === that.month && this.day === that.day && this.hour === that.hour && this.minute === that.minute && this.second === that.second && this.millisecond > that.millisecond);
    }

    equals(that) {
        return this.year === that.year && this.month === that.month && this.day === that.day && this.hour === that.hour && this.minute === that.minute && this.second === that.second && this.millisecond === that.millisecond;
    }

    equalsDate(that) {
        return this.year === that.year && this.month === that.month && this.day === that.day;
    }

    getThursdayOfWeek(year = this.year, month = this.month, day = this.day) {
        return this.addDays(4 - this.getDayOfWeek(year, month, day), year, month, day);
    }

    getWeekOfYear(year = this.year, month = this.month, day = this.day) {
        let thursday = this.getThursdayOfWeek(year, month, day);
        return Math.floor((thursday.getDayOfYear() - 1) / 7) + 1;
    }

    getWeekYear(year = this.year, month = this.month, day = this.day) {
        let thursday = this.getThursdayOfWeek(year, month, day);
        return thursday.getYear();
    }

    getEasterDate(year = this.year) {
        let a = year % 19;
        let b = Math.floor(year / 100);
        let c = year % 100;
        let d = Math.floor(b / 4);
        let e = b % 4;
        let f = Math.floor((b + 8) / 25);
        let g = Math.floor((b - f + 1) / 3);
        let h = (a * 19 + b + 15 - d - g) % 30;
        let i = Math.floor(c / 4);
        let j = c % 4;
        let k = ((e + i) * 2 + 32 - h - j) % 7;
        let l = Math.floor(((k * 2 + h) * 11 + a) / 451);
        let x = h + k + 114 - l * 7;
        let m = Math.floor(x / 31);
        let n = x % 31;
        return new LocalDateTime(year, m, n + 1);
    }

    getHolidays() {
        if (!LocalDateTime.holidays.hasOwnProperty(this.year)) {
            let result = [];
            // easter independent holidays
            result.push(new LocalDateTime(this.year, 1, 1));
            result.push(new LocalDateTime(this.year, 1, 6));
            result.push(new LocalDateTime(this.year, 5, 1));
            result.push(new LocalDateTime(this.year, 10, 3));
            result.push(new LocalDateTime(this.year, 11, 1));
            result.push(new LocalDateTime(this.year, 12, 24));
            result.push(new LocalDateTime(this.year, 12, 25));
            result.push(new LocalDateTime(this.year, 12, 26));
            result.push(new LocalDateTime(this.year, 12, 31));


            // easter dependent holidays
            let easterSunday = this.getEasterDate();
            result.push(easterSunday.addDays(-2));
            result.push(easterSunday.addDays(1));
            result.push(easterSunday.addDays(39));
            result.push(easterSunday.addDays(50));
            result.push(easterSunday.addDays(60));

            LocalDateTime.holidays[this.year] = result;
        }
        return LocalDateTime.holidays[this.year];
    }

    isHoliday() {
        if (this.lazyValIsHoliday === undefined)
            this.setLazyValIsHoliday();
        return this.lazyValIsHoliday;
    }

    setLazyValIsHoliday() {
        this.lazyValIsHoliday = this.getHolidays().some(holiday => holiday.equalsDate(this));
    }

    toLongGermanDateString() {
        return this.getDayOfWeekName() + ", " + this.getDay() + ". " + this.getMonthName() + " " + this.getYear();
    }

    toShortGermanMonthYearString() {
        return this.getMonth() + "/" + this.getYear();
    }

    toShortGermanDateString() {
        return (this.getDay() < 10 ? "0" + this.getDay() : this.getDay()) + "." + (this.getMonth() < 10 ? "0" + this.getMonth() : this.getMonth()) + "." + this.getYear();
    }

    toGermanTimeStringWithoutSeconds() {
        let hour = this.getHour() < 10 ? "0" + this.getHour() : this.getHour();
        let minute = this.getMinute() < 10 ? "0" + this.getMinute() : this.getMinute();
        return hour + ":" + minute;
    }

    toGermanTimeString() {
        let second = this.getSecond() < 10 ? "0" + this.getSecond() : this.getSecond();
        return this.toGermanTimeStringWithoutSeconds() + ":" + second;
    }

    toGermanDateTimeString() {
        return this.toShortGermanDateString() + " " + this.toGermanTimeString();
    }

    toGermanDateTimeStringWithoutSeconds() {
        return this.toShortGermanDateString() + " " + this.toGermanTimeStringWithoutSeconds();
    }

    toLongGermanDateTimeStringWithoutSeconds() {
        return this.toLongGermanDateString() + ", " + this.toGermanTimeStringWithoutSeconds() + " Uhr";
    }

    toShortGermanTimeString() {
        let hour = this.getHour() < 10 ? "0" + this.getHour() : this.getHour();
        let minute = this.getMinute() < 10 ? "0" + this.getMinute() : this.getMinute();
        return hour + ":" + minute;
    }

    toISOString() {
        let year = this.year;
        let month = this.month < 10 ? '0' + this.month : this.month;
        let day = this.day < 10 ? '0' + this.day : this.day;
        let hour = this.hour < 10 ? '0' + this.hour : this.hour;
        let minute = this.minute < 10 ? '0' + this.minute : this.minute;
        let second = this.second < 10 ? '0' + this.second : this.second;
        let millisecond = this.millisecond < 10 ? '00' + this.millisecond : this.millisecond < 100 ? '0' + this.millisecond : this.millisecond;
        return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second + "." + millisecond;
    }

    toString() {
        return this.toISOString();
    }
}

export default LocalDateTime;