import React from 'react';
import LoginView from "./view/LoginView.js"
import EditorView from "./view/EditorView";
import ajax from "./ajax.js";
import LocalDateTime from "./util/LocalDateTime";
import Dialog from "./component/Dialog";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dialog: undefined,
            loggedIn: true,
            modify: true,
            loadingNote: false,
            loadingNotes: false,
            filter: "",
            note: this.getNewNote(),
            notes: [],
            datetimeIsValid: true,
            keywordsAreValid: true,
            validKeywords: []
        }
        this.onNoteItemClicked = this.onNoteItemClicked.bind(this);
        this.onSaveClicked = this.onSaveClicked.bind(this);
        this.onDiscardClicked = this.onDiscardClicked.bind(this);
        this.onNewClicked = this.onNewClicked.bind(this);
        this.onDeleteClicked = this.onDeleteClicked.bind(this);
        this.onModifyClicked = this.onModifyClicked.bind(this);
        this.onChangeContent = this.onChangeContent.bind(this);
        this.onChangeTitle = this.onChangeTitle.bind(this);
        this.onChangeDatetime = this.onChangeDatetime.bind(this);
        this.onChangeKeywords = this.onChangeKeywords.bind(this);
        this.onSetFilter = this.onSetFilter.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.beforeUnload = this.beforeUnload.bind(this);
    }

    componentDidMount() {
        window.addEventListener('beforeunload', this.beforeUnload);
        window.addEventListener("keydown", this.onKeyDown);
        window.addEventListener("keyup", this.onKeyUp);
        this.refreshList()
        this.loadValidKeywords();
        let queryString = window.location.search;
        let urlParams = new URLSearchParams(queryString);
        if (urlParams.has("note"))
            this.loadNote(parseInt(urlParams.get("note")));

    }

    loadValidKeywords() {
        ajax("listKeywords", {}, (response) => {
            if (response.success)
                this.setState({validKeywords: response.keywords});
            else
                this.setState({loggedIn: false, loadingNotes: false});
        });
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.beforeUnload);
        window.removeEventListener("keydown", this.onKeyDown);
        window.removeEventListener("keyup", this.onKeyUp);
    }

    onKeyDown(event) {
        if (this.state.loggedIn) {
            switch (event.which) {
                case 91:
                case 224:
                    this.cmdPressed = true;
                    break;
                case 83:
                case 13:
                    if (this.cmdPressed) {
                        event.preventDefault();
                        event.stopPropagation();
                        if (this.state.modify && this.state.datetimeIsValid && this.state.keywordsAreValid)
                            this.onSaveClicked();
                    }
                    break;
                case 27:
                    event.preventDefault();
                    event.stopPropagation();
                    if (this.state.modify)
                        this.onDiscardClicked();
                    break;
                case 78:
                    if (!this.state.modify) {
                        event.preventDefault();
                        event.stopPropagation();
                        this.onNewClicked();
                    }
                    break;
                case 69:
                    if (!this.state.modify) {
                        event.preventDefault();
                        event.stopPropagation();
                        this.onModifyClicked();
                    }
                    break;
                case 68:
                case 46:
                case 8:
                    if (!this.state.modify) {
                        event.preventDefault();
                        event.stopPropagation();
                        this.onDeleteClicked();
                    }
                    break;
                case 38:
                    if (!this.state.modify) {
                        let index = this.getIndexOfNote();
                        if (index !== -1 && index > 0)
                            this.loadNote(this.state.notes[index - 1].id);
                        event.stopPropagation();
                        event.preventDefault();
                    }
                    break;
                case 40:
                    if (!this.state.modify) {
                        let index = this.getIndexOfNote();
                        console.log(index);
                        if (index !== -1 && index < this.state.notes.length - 1)
                            this.loadNote(this.state.notes[index + 1].id);
                        event.stopPropagation();
                        event.preventDefault();
                    }
                    break;
                default:
            }
        }
    }

    getIndexOfNote() {
        return this.state.notes.findIndex(note => note.id === this.state.note.id);
    }

    onKeyUp(event) {
        switch (event.which) {
            case 91:
            case 224:
                this.cmdPressed = false;
                break;
            default:
        }
    }

    beforeUnload(event) {
        console.log(this.state.note);
        console.log(this.state.note.id === null);
        console.log(this.state.note.title === "<br>");
        console.log(this.state.note.content === "");
        if (this.state.modify && !(
            this.state.note.id === undefined &&
            this.state.note.title === "<br>" &&
            this.state.note.content === "")) {
            // Cancel the event as stated by the standard.
            event.preventDefault();
            // Older browsers supported custom message
            event.returnValue = 'Die Änderungen wurden noch nicht gespeichert. Seite trotzdem verlassen?';
        }
    }

    refreshList() {
        this.setState({loadingNotes: true});
        ajax("list", {filter: this.state.filter}, (response) => {
            if (response.success)
                this.setState({notes: response.notes, loadingNotes: false});
            else
                this.setState({loggedIn: false, loadingNotes: false});
        });
    }

    onDiscardClicked() {
        this.setState({
            dialog:
                <Dialog
                    text={"Sollen die Änderungen wirklich verworfen werden?"}
                    buttons={[
                        {
                            text: "Ja",
                            onClick: () => {
                                if (this.state.note.id)
                                    this.loadNote(this.state.note.id);
                                else
                                    this.setState({note: this.getNewNote(), modify: true});
                                this.setState({dialog: undefined});
                            }
                        },
                        {
                            text: "Nein",
                            onClick: () => {
                                this.setState({dialog: undefined});
                            }
                        }
                    ]}
                />
        });
    }

    onDeleteClicked() {
        if (this.state.note.id)
            this.setState({
                dialog:
                    <Dialog
                        text={"Soll die ausgewählte Notiz wirklich gelöscht werden?"}
                        buttons={[
                            {
                                text: "Ja",
                                onClick: () => {
                                    this.setState({dialog: undefined});
                                    ajax("delete", {id: this.state.note.id}, (response) => {
                                        if (response.success) {
                                            let nearestNote = this.getNearestNote();
                                            this.setState({note: nearestNote});
                                            if (nearestNote.id)
                                                this.loadNote(nearestNote.id);
                                            this.refreshList();
                                        } else
                                            this.setState({loggedIn: false});
                                    });
                                }
                            },
                            {
                                text: "Nein",
                                onClick: () => {
                                    this.setState({dialog: undefined});
                                }
                            }
                        ]}
                    />
            });
    }

    onModifyClicked() {
        this.setState({modify: true});
    }


    getNearestNote() {
        let index = this.getIndexOfNote();
        if (index !== -1 && index < this.state.notes.length - 1)
            return this.state.notes[index + 1];
        else if (index !== -1 && index > 0)
            return this.state.notes[index - 1];
        else
            return this.getNewNote();
    }


    onNoteItemClicked(note) {
        if (!this.state.modify || (this.state.note.id === undefined && this.state.note.title === "<br>" && this.state.note.content === ""))
            this.loadNote(note.id);
    }

    loadNote(id) {
        this.setState({loadingNote: true})
        ajax("get", {id: id}, (response) => {
            this.setState({loadingNote: false});
            if (response.success) {
                this.setState({
                    note: response.note,
                    keywords: response.note.keywords.join(" "),
                    modify: false
                });
            } else if (response.error === "not existing id") {
                this.setState({
                    note: this.getNewNote(),
                    modify: false,
                    dialog:
                        <Dialog
                            text={"Die angeforderte Notiz existiert nicht!"}
                            buttons={[
                                {
                                    text: "Ok",
                                    onClick: () => {
                                        this.setState({dialog: undefined});
                                    }
                                }
                            ]}
                        />
                });
            } else
                this.setState({loggedIn: false});
        });
    }

    getNewNote() {
        return {datetime: LocalDateTime.now(), title: "<br>", content: "", keywords: ["Schmierzettel"]};
    }

    onSaveClicked() {
        let command = this.state.note.id === undefined ? "create" : "update";
        let note = this.state.note;
        if (note.title.endsWith("<br>"))
            note.title = note.title.substring(0, note.title.length - 4);
        ajax(command, {note: this.state.note, force: false}, (response) => {
            if (response.success) {
                this.setState({modify: false});
                this.setState({note: response.note}, () => this.refreshList());
            } else if (response.error === "version conflict")
                this.setState({
                    dialog:
                        <Dialog
                            text={"Es gibt einen Versionskonflikt. Notiz trotzdem speichern?"}
                            buttons={[
                                {
                                    text: "Ja",
                                    onClick: () => {
                                        this.setState({dialog: undefined});
                                        ajax("update", {note: this.state.note, force: true}, (response) => {
                                            if (response.success) {
                                                this.setState({modify: false});
                                                this.setState({note: response.note}, () => this.refreshList());
                                            } else
                                                this.setState({loggedIn: false});
                                        });
                                    }
                                },
                                {
                                    text: "Nein",
                                    onClick: () => {
                                        this.setState({dialog: undefined});
                                    }
                                }
                            ]}
                        />
                });
            else
                this.setState({loggedIn: false});
        });
    }

    onNewClicked() {
        this.setState({
            note: this.getNewNote(),
            keywords: "",
            modify: true
        })
    }

    onSetFilter(filter) {
        if (filter !== this.state.filter)
            this.setState({"filter": filter}, () => this.refreshList());
    }

    onChangeContent(event) {
        let note = {...this.state.note, content: event.target.value};
        this.setState({note: note})
    }

    onChangeTitle(event) {
        let note = {...this.state.note, title: event.target.value};
        this.setState({note: note})
    }

    onChangeDatetime(datetime) {
        if (datetime !== null) {
            let note = {...this.state.note, datetime: datetime};
            this.setState({note: note, datetimeIsValid: true});
        } else if (this.state.datetimeIsValid) {
            this.setState({datetimeIsValid: false});
        }
    }

    onChangeKeywords(keywords) {
        if (keywords !== null) {
            let note = {...this.state.note, keywords: keywords};
            this.setState({note: note, keywordsAreValid: true});
        } else if (this.state.keywordsAreValid) {
            this.setState({keywordsAreValid: false});
        }
    }


    render() {
        if (this.state.loggedIn)
            return (
                <>
                    {this.state.dialog}
                    <EditorView
                        notes={this.state.notes}
                        note={this.state.note}
                        onNoteItemClicked={this.onNoteItemClicked}
                        onLoggedOut={() => this.setState({loggedIn: false})}
                        onChangeContent={this.onChangeContent}
                        onChangeTitle={this.onChangeTitle}
                        onChangeDatetime={this.onChangeDatetime}
                        onChangeKeywords={this.onChangeKeywords}
                        onSaveClicked={this.onSaveClicked}
                        onDiscardClicked={this.onDiscardClicked}
                        onNewClicked={this.onNewClicked}
                        onDeleteClicked={this.onDeleteClicked}
                        onModifyClicked={this.onModifyClicked}
                        onSetFilter={this.onSetFilter}
                        filter={this.state.filter}
                        modify={this.state.modify}
                        loadingNote={this.state.loadingNote}
                        loadingNotes={this.state.loadingNotes}
                        valid={this.state.datetimeIsValid && this.state.keywordsAreValid}
                        validKeywords={this.state.validKeywords}
                    />
                </>
            );
        else
            return (
                <LoginView
                    onLoggedIn={() => {
                        this.setState({loggedIn: true});
                        this.refreshList();
                        this.loadValidKeywords();
                    }}
                />
            );
    }
}

export default App;
