import React from "react";
import "./SearchBar.css";

class SearchBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: this.props.filter,
            modified: false
        }
        this.onClick = this.onClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onKeyDown(event) {
        event.stopPropagation();
        if (event.which === 13)
            this.onClick();
    }

    onClick() {
        this.props.onSetFilter(this.state.filter);
        this.setState({modified: false});
    }

    onChange(event) {
        this.props.onSetFilter("");
        this.setState({
            filter: event.target.value,
            modified: event.target.value !== ""
        });
    }

    render() {
        return (
            <div className="search-bar">
                <input
                    className="search-bar-input"
                    style={{backgroundColor: this.state.modified ? "lightyellow" : "white"}}
                    type="text"
                    onKeyDown={this.onKeyDown}
                    onChange={this.onChange}
                    value={this.state.filter}
                />
                <button
                    className="search-bar-button"
                    onClick={this.onClick}
                >
                    Suchen
                </button>
            </div>
        );
    }
}

export default SearchBar;