import React from "react";
import "./ButtonBar.css";

class ButtonBar extends React.Component {
    render() {
        if (this.props.modify)
            return (
                <div className="button-bar">
                    <button className="button-bar-button" onClick={this.props.onSaveClicked}
                            disabled={!this.props.valid}>
                        Speichern
                    </button>
                    <button className="button-bar-button" onClick={this.props.onDiscardClicked}>
                        Verwerfen
                    </button>

                </div>
            );
        else
            return (
                <div className="button-bar">
                    <button className="button-bar-button" onClick={this.props.onNewClicked}>
                        Neu
                    </button>
                    <button className="button-bar-button" onClick={this.props.onModifyClicked}>
                        Bearbeiten
                    </button>
                    <button className="button-bar-button" onClick={this.props.onDeleteClicked}>
                        Löschen
                    </button>
                </div>
            );
    }
}


export default ButtonBar;