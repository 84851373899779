import React from "react";
import ajax from "../ajax";

import "./LoginView.css";

class LoginView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            loginFailed: false
        }
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onLoginClicked = this.onLoginClicked.bind(this);
    }

    onKeyDown(event) {
        if (event.which === 13)
            this.onLoginClicked();
    }

    onLoginClicked() {
        ajax(
            "login",
            {username: this.state.username, password: this.state.password},
            (response) => {
                if (response.success)
                    this.props.onLoggedIn();
                else
                    this.setState({username: "", password: "", loginFailed: true});
            },
            () => alert("Der Server antwortet nicht."));
    }

    render() {
        return (
            <div className="login-view" onKeyDown={this.onKeyDown}>
                <h1>Notizen</h1>
                <div className="login-form">
                    <p className="login-view">Benutzer:</p>
                    <input className="login-view"
                           style={{backgroundColor: this.state.loginFailed ? "#ffeeee" : "white"}}
                           type="text"
                           value={this.state.username}
                           onChange={(event) => this.setState({username: event.target.value})}/>
                    <p className="login-view">Passwort:</p>
                    <input className="login-view"
                           style={{backgroundColor: this.state.loginFailed ? "#ffeeee" : "white"}}
                           type="password"
                           value={this.state.password}
                           onChange={(event) => this.setState({password: event.target.value})}/>
                    <div style={{
                        textAlign: "right",
                        gridColumn: "span 2"
                    }}>
                        <button
                            onClick={this.onLoginClicked}>
                            Anmelden
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default LoginView;