import React from "react";

class KeywordsEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: true,
            text: this.props.keywords.join(" ")
        }
        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        let text = event.target.value;
        let valid = this.isValid(text);
        let keywords = valid ?
            text.split(" ") :
            null;
        this.props.onChange(keywords);
        this.setState({
            valid: valid,
            text: text
        });
    }

    isValid(keywords) {
        let validKeywords = this.props.validKeywords.map(keyword => keyword.name);
        let tokens = keywords.split(" ");
        return !tokens.some(token => !validKeywords.includes(token));
    }

    render() {
        return (
            <input
                type="text"
                disabled={this.props.disabled}
                className={this.state.valid ? "keywordseditor" : "keywordseditor keywordseditor-invalid"}
                value={this.state.text}
                onChange={this.onChange}
            />
        );
    }
}

export default KeywordsEditor;