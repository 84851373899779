import React from "react";
import "./NoteItem.css";

class NoteItem extends React.Component {
    render() {
        return (
            <div className={this.props.selected ? "note-item note-item-selected" : "note-item"} onClick={this.props.onClick}>
                {this.props.note.title}<br/>
                <span
                    className={"note-item-datetime"}>{this.props.note.datetime.toGermanDateTimeStringWithoutSeconds() + " (#" + this.props.note.id + ")"}</span>
            </div>
        );
    }
}

export default NoteItem;