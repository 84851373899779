import $ from "jquery";
import LocalDateTime from "./util/LocalDateTime";

function ajax(command, parameters, callbackSuccess, callbackFail = doNothing) {
    let request = $.ajax({
        url: "ajax.php",
        type: "post",
        data: {command: command, parameters: JSON.stringify(parameters)},
        dataType: "text",
        timeout: 5000
    });
    request.done(function (text) {
        console.log(text);
        let response = JSON.parse(text, dateParser);
        console.log(response);
        callbackSuccess(response, parameters);
    });
    request.fail(function (jqXHR, textStatus, errorThrown) {
        console.log("Ajax request failed with textStatus '" + textStatus + "' and errorThrown '" + errorThrown + "'");
        callbackFail(textStatus, errorThrown);
    });
}

function doNothing() {

}

function dateParser(key, value) {
    if (typeof value === 'string' && LocalDateTime.regExpIso.exec(value))
        return LocalDateTime.parseIso(value);
    else
        return value;
}

export default ajax;