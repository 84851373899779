import React from "react";
import "./Dialog.css";

class Dialog extends React.Component {
    render() {
        return (
            <>
                <div className="background"/>
                <div className="dialog">
                    <div className="dialog-text">
                        {this.props.text}
                    </div>
                    <div className="dialog-buttons">
                        {this.props.buttons.map((button, index) =>
                            <button
                                key={index}
                                className="dialog-button"
                                onClick={button.onClick}
                            >
                                {button.text}
                            </button>)}
                    </div>
                </div>
            </>
        );
    }
}

export default Dialog;