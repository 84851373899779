import React from "react";
import LocalDateTime from "../util/LocalDateTime";

class DateTimeEditor extends React.Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.state = {
            valid: true
        }
    }

    onChange(event) {
        let datetime = LocalDateTime.parseGermanDateTimeWithoutSeconds(event.target.value);
        this.props.onChange(datetime);
        this.setState({valid: datetime !== null})
    }

    render() {
        let style = this.state.valid ?
            {} :
            {color: "red"}
        return (
            <input
                className={this.props.className}
                style={style}
                type="text"
                disabled={this.props.disabled}
                defaultValue={this.props.value.toGermanDateTimeStringWithoutSeconds()}
                onChange={this.onChange}
            />
        );
    }
}

export default DateTimeEditor;