import React from "react";
import ajax from "../ajax";

import "./EditorView.css";
import NoteItem from "../component/NoteItem";
import ButtonBar from "../component/ButtonBar";
import ContentEditable from "react-contenteditable";
import SearchBar from "../component/SearchBar";
import ReactMarkdown from "react-markdown";
import gfm from 'remark-gfm'
import images from 'remark-images'
import emoji from 'remark-emoji';
import DateTimeEditor from "../component/DateTimeEditor";
import KeywordsEditor from "../component/KeywordsEditor";

class EditorView extends React.Component {
    constructor(props) {
        super(props);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onInput = this.onInput.bind(this);
        this.onLogoutClicked = this.onLogoutClicked.bind(this);
    }

    onKeyDown(event) {
        // TODO
    }

    onInput(event) {

    }

    onLogoutClicked() {
        ajax(
            "logout",
            {},
            () => {
                this.props.onLoggedOut();
            },
            () => alert("Der Server antwortet nicht."));
    }

    render() {
        return (
            <div className="editor-view">
                <div className="navigation">
                    <div className="branding" onClick={this.onLogoutClicked}>Notizen</div>
                    <SearchBar filter={this.props.filter} onSetFilter={this.props.onSetFilter}/>
                    <div className="itemlist">
                        {this.props.loadingNotes ?
                            "" :
                            this.props.notes.map(note =>
                                <NoteItem note={note}
                                          selected={this.props.note && this.props.note.id === note.id}
                                          onClick={() => this.props.onNoteItemClicked(note)}
                                          key={note.id}/>)}
                    </div>
                    <div className="itemlist-status">
                        {this.props.loadingNotes ?
                            "Wird abgerufen..." :
                            this.props.notes.length === 1 ?
                                "1 Notiz" :
                                this.props.notes.length + " Notizen"}
                    </div>
                    <ButtonBar
                        modify={this.props.modify}
                        valid={this.props.valid}
                        onSaveClicked={this.props.onSaveClicked}
                        onDiscardClicked={this.props.onDiscardClicked}
                        onNewClicked={this.props.onNewClicked}
                        onDeleteClicked={this.props.onDeleteClicked}
                        onModifyClicked={this.props.onModifyClicked}
                    />
                </div>
                <div className="content">
                    {this.props.modify ?
                        <DateTimeEditor
                            className="datetime"
                            value={this.props.note.datetime}
                            onChange={(datetime) => this.props.onChangeDatetime(datetime)}
                        />
                        :
                        <div className="datetime">
                            {this.props.note.datetime.toLongGermanDateTimeStringWithoutSeconds()}
                        </div>
                    }
                    <ContentEditable
                        disabled={!this.props.modify}
                        className="title"
                        html={this.props.note.title}
                        onChange={this.props.onChangeTitle}
                    />
                    {this.props.modify ?
                        <textarea
                            className="editor"
                            value={this.props.note.content}
                            onChange={this.props.onChangeContent}
                        />
                        :
                        this.props.loadingNote ?
                            "Notiz wird geladen..." :
                            <ReactMarkdown
                                className="editor"
                                children={this.props.note.content}
                                plugins={[gfm, images, emoji]}
                                linkTarget={(url, text, title) => url.startsWith("?") ? "" : "_blank"}
                            />
                    }
                    {this.props.modify ?
                        <KeywordsEditor
                            disabled={!this.props.modify}
                            validKeywords={this.props.validKeywords}
                            keywords={this.props.note.keywords}
                            onChange={this.props.onChangeKeywords}
                        />
                        :
                        <div className="keywordseditor">
                            {this.props.note.keywords === null?
                                "":
                                this.props.note.keywords.join(" ")}
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default EditorView;